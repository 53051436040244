import { hasNames } from "../utils";
import { graphql } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { useState, useEffect } from "react";

import Button from "../components/button";
import Frame from "../components/frame";
import { HeadMeta } from "../components/head-meta";
import TeamMember from "../components/team-member";
import TeamMemberModal from "../components/team-member-modal";
import InternalHero from "../components/internal-hero";
import ContactUsFooter from "../components/contact-us-footer";

import * as com from "../styles/common.module.scss";
import * as styles from "../styles/advisors.module.scss";
import * as grd from "../styles/grid.module.scss";

export const Head = () => {
	return <HeadMeta title="Team" />;
};

const AdvisorsPage = ({ data }) => {
	const [activeFilter, setActiveFilter] = useState("Investment Team");

	const [memberOpen, setMemberOpen] = useState(-1);

	const placements = ['Investment Team', 'Operations']

	return (
		<Frame section='strategies'>
			<InternalHero
					eyebrow='Our Team'
					backdrop={
						<StaticImage
							src='../images/heros/cs-hero-team.jpg'
							alt="background image"
							aria-hidden="true"
							placeholder="blurred"
							layout="fullWidth"
							loading="eager"
						/>
					}>
					<h1>Meet Our Strategies Experts</h1>
					<p>
					Our team of recognized experts utilize their experience and insight to empower our goal of delivering the highest and most consistent total returns possible while minimizing the risk of loss.
					</p>
				</InternalHero>
			<div className={hasNames(grd.container, styles.copyBlock)}>
				<div className={styles.copyBlockInner}>
					<h2>Our Team</h2>
				</div>
			</div>
			<div className={com.filterBox}>
				{
				placements
					.sort()
					.map((placement) => {
						return (
							<Button
								type="filter"
								active={activeFilter === placement}
								onClick={() => {
									setActiveFilter(placement);
								}}>
								{placement}
							</Button>
						);
					})}
				<Button
					type="filter"
					active={activeFilter === ""}
					onClick={() => {
						setActiveFilter("");
					}}>
					Show All
				</Button>
			</div>
			{/* Show All */}
			<div className={hasNames(grd.gridContainer, styles.membersBlock)}>
				{data.showAll.nodes.map((person, index) => {
					if (
						activeFilter !== ""
					) {
						return null;
					}

					person.frontmatter.name =
						person.frontmatter.first_name + " " + person.frontmatter.last_name;
					return (
						<TeamMember
							key={"team-member-" + person.id}
							className={hasNames(grd.col3, grd.colMd4, grd.colSm6)}
							image={
								<GatsbyImage
									image={getImage(person.frontmatter.image)}
									alt={person.frontmatter.name}
								/>
							}
							name={person.frontmatter.name}
							position={person.frontmatter.title_short}
							openFn={() => {
								setMemberOpen(index);
								document.body.classList.add("scrollLocked");
							}}
							closeFn={() => {
								setMemberOpen(-1);
								document.body.classList.remove("scrollLocked");
							}}
							nextFn={
								index + 1 === data.showAll.nodes.length
									? null
									: () => {
											setMemberOpen(index + 1);
									  }
							}
							prevFn={
								index === 0
									? null
									: () => {
											setMemberOpen(index - 1);
									  }
							}
							isOpen={index === memberOpen}>
							<TeamMemberModal
								name={person.frontmatter.name}
								position={person.frontmatter.title_long}
								linkedin={person.frontmatter.linkedin}
								image={
									<GatsbyImage
										image={getImage(person.frontmatter.image)}
										alt={person.frontmatter.name}
									/>
								}>
								<div
									dangerouslySetInnerHTML={{
										__html: person.html,
									}}></div>
							</TeamMemberModal>
						</TeamMember>
					);
				})}
			</div>
			{/* Cynosure Wealth Management */}
			<div className={hasNames(grd.gridContainer, styles.membersBlock)}>
				{data.strategies.nodes.map((person, index) => {
					const groupName = (person.frontmatter.strategies)? 'Investment Team' : null
					if (
						activeFilter !== groupName
					) {
						return null
					}

					person.frontmatter.name =
						person.frontmatter.first_name + " " + person.frontmatter.last_name;
					return (
						<TeamMember
							key={"team-member-" + person.id}
							className={hasNames(grd.col3, grd.colMd4, grd.colSm6)}
							image={
								<GatsbyImage
									image={getImage(person.frontmatter.image)}
									alt={person.frontmatter.name}
								/>
							}
							name={person.frontmatter.name}
							position={person.frontmatter.title_short}
							openFn={() => {
								setMemberOpen(index);
								document.body.classList.add("scrollLocked");
							}}
							closeFn={() => {
								setMemberOpen(-1);
								document.body.classList.remove("scrollLocked");
							}}
							nextFn={
								index + 1 === data.strategies.nodes.length
									? null
									: () => {
											setMemberOpen(index + 1);
									  }
							}
							prevFn={
								index === 0
									? null
									: () => {
											setMemberOpen(index - 1);
									  }
							}
							isOpen={index === memberOpen}>
							<TeamMemberModal
								name={person.frontmatter.name}
								position={person.frontmatter.title_long}
								linkedin={person.frontmatter.linkedin}
								image={
									<GatsbyImage
										image={getImage(person.frontmatter.image)}
										alt={person.frontmatter.name}
									/>
								}>
								<div
									dangerouslySetInnerHTML={{
										__html: person.html,
									}}></div>
							</TeamMemberModal>
						</TeamMember>
					);
				})}
			</div>
			{/* Business */}
			<div className={hasNames(grd.gridContainer, styles.membersBlock)}>
				{data.business.nodes.map((person, index) => {
					const groupName = (person.frontmatter.business)? 'Operations' : null
					if (
						activeFilter !== groupName
					) {
						return null
					}

					person.frontmatter.name =
						person.frontmatter.first_name + " " + person.frontmatter.last_name;
					return (
						<TeamMember
							key={"team-member-" + person.id}
							className={hasNames(grd.col3, grd.colMd4, grd.colSm6)}
							image={
								<GatsbyImage
									image={getImage(person.frontmatter.image)}
									alt={person.frontmatter.name}
								/>
							}
							name={person.frontmatter.name}
							position={person.frontmatter.title_short}
							openFn={() => {
								setMemberOpen(index);
								document.body.classList.add("scrollLocked");
							}}
							closeFn={() => {
								setMemberOpen(-1);
								document.body.classList.remove("scrollLocked");
							}}
							nextFn={
								index + 1 === data.business.nodes.length
									? null
									: () => {
											setMemberOpen(index + 1);
									  }
							}
							prevFn={
								index === 0
									? null
									: () => {
											setMemberOpen(index - 1);
									  }
							}
							isOpen={index === memberOpen}>
							<TeamMemberModal
								name={person.frontmatter.name}
								position={person.frontmatter.title_long}
								linkedin={person.frontmatter.linkedin}
								image={
									<GatsbyImage
										image={getImage(person.frontmatter.image)}
										alt={person.frontmatter.name}
									/>
								}>
								<div
									dangerouslySetInnerHTML={{
										__html: person.html,
									}}></div>
							</TeamMemberModal>
						</TeamMember>
					);
				})}
			</div>
			<ContactUsFooter />
		</Frame>
	);
};

export default AdvisorsPage;

export const query = graphql`
	query AdvisorPageQuery {
		showAll: allMarkdownRemark(
			filter: {
				fileAbsolutePath: { regex: "/src/cms/data/team/" }
			}
			sort: { fields: frontmatter___last_name }
		) {
			nodes {
				id
				frontmatter {
					first_name
					last_name
					title_long
					title_short
					linkedin
					image {
						childImageSharp {
							gatsbyImageData(
								width: 360
								height: 360
								quality: 90
								transformOptions: { cropFocus: NORTH }
							)
						}
					}
				}
				html
			}
		}
		strategies: allMarkdownRemark(
			filter: {
				fileAbsolutePath: { regex: "/src/cms/data/team/" }
				frontmatter: { strategies: { eq: true } }
			}
			sort: { fields: frontmatter___strategies_placement }
		) {
			nodes {
				id
				frontmatter {
					first_name
					last_name
					title_long
					title_short
					linkedin
					strategies
					strategies_placement
					image {
						childImageSharp {
							gatsbyImageData(
								width: 360
								height: 360
								quality: 90
								transformOptions: { cropFocus: NORTH }
							)
						}
					}
				}
				html
			}
		}
		business: allMarkdownRemark(
			filter: {
				fileAbsolutePath: { regex: "/src/cms/data/team/" }
				frontmatter: { business: { eq: true } }
			}
			sort: { fields: frontmatter___business_placement }
		) {
			nodes {
				id
				frontmatter {
					first_name
					last_name
					title_long
					title_short
					linkedin
					business
					business_placement
					image {
						childImageSharp {
							gatsbyImageData(
								width: 360
								height: 360
								quality: 90
								transformOptions: { cropFocus: NORTH }
							)
						}
					}
				}
				html
			}
		}
	}
`;
